import './app.css'
import $ from 'jquery'
import LocomotiveScroll from 'locomotive-scroll';

var page = $('body').attr('id'),
	lastWindowWidth = 0,
	sizes = {
		width: window.innerWidth,
		height: window.innerHeight
	},
	isMobile,
	vh,
	scroll;

(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? isMobile = true : isMobile = false

$(window).on('load', function(){

	$.ready.then(function(){

		appendImgs()

		onWindowResize()

		window.addEventListener( 'resize', onWindowResize );

		if(page == 'list') {

			scroll = new LocomotiveScroll(
			{
				el: document.querySelector('[data-scroll-container]'),
				smooth: true,
				scrollFromAnywhere: true,
				getDirection: true,
				smartphone: {
					smooth: false,
					lerp: 0
				},
				tablet: {
					smooth: false,
					lerp: 0
				}
			});

		}


	});

})

var isHorizontal = false,
	isFirstBuild = true

function buildScroll(){

	if(sizes.width >= 1000) {

		if(isHorizontal == false) {

			isHorizontal = true

			if(scroll) {
				scroll.destroy();
				scroll = null
			}

			scroll = new LocomotiveScroll(
			{
				el: document.querySelector('[data-scroll-container]'),
				smooth: true,
				direction: 'horizontal',
				scrollFromAnywhere: true,
				lerp: 0.08,
				smartphone: {
					breakpoint: 0,
					smooth: true
				},
				tablet: {
					breakpoint: 0,
					smooth: true
				},
			});

		}


	} else {

		if(isHorizontal == true) {

			isHorizontal = false

			if(scroll) { 
				scroll.destroy();
				scroll = null
			}

			scroll = new LocomotiveScroll(
			{
				el: document.querySelector('[data-scroll-container]'),
				smooth: true,
				scrollFromAnywhere: true,
				direction: 'vertical',
				getDirection: true,
				smartphone: {
					smooth: false
				},
				tablet: {
					smooth: false
				}
			});

		}

	}

}

function onWindowResize(){

	sizes.width = window.innerWidth
	sizes.height = window.innerHeight
	vh = sizes.height * 0.01;

	if(isMobile) {

		if(sizes.width != lastWindowWidth) {

			setH();
		}

	} else {

		setH();

	}

	clearTimeout(window.scrollUpdate);

	window.scrollUpdate = setTimeout(function(){

		if(scroll){scroll.update()};

	}, 500);

	function setH(){

		document.documentElement.style.setProperty('--vh', `${vh}px`);

	}

	clearTimeout(window.resizedFinished);

	window.resizedFinished = setTimeout(function(){

		if(!isMobile) { setH(); }

	}, 500);


	lastWindowWidth = sizes.width

	if(page == 'catalogue') {

		buildScroll()
	}

}

$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
}

$.fn.isInViewportH = function() {
	var elementTop = $(this).offset().left;
	var elementBottom = elementTop + $(this).outerWidth();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).width();
	return elementBottom > viewportTop && elementTop < viewportBottom;
}

function support_format_webp(img) {

	var elem = document.createElement('canvas')

	if (!!(elem.getContext && elem.getContext('2d'))) { return img.substr(0, img.lastIndexOf(".")) + ".webp" } else { return img}

	return img
}

function appendImgs(val){

	var appendBGs = $('body').find('.load_bg');

	appendBGs.each(function(i){

		var t = $(this),
			s = t.attr('data-src');

		t.css({ 'background-image': 'url('+ support_format_webp(s) +')' })

		t.removeClass('load_bg')

	});

}